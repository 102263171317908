.nunito {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}

.nunito-light {
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
}


/*.nunito {
  font-family: 'Nunito', sans-serif
}*/

.model-viewer-container {
    position: relative;
    overflow: visible;

}

model-viewer::part(default-ar-button) {
    display: none;
}



/*.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 300;
}*/